import React, {ChangeEvent, ReactNode} from 'react';
import {classPrefix} from "../../utilities/global";
import classnames from '../../utilities/classnames';

interface Props {
  name?: string,
  children?: ReactNode,
  value?: any,
  onChange?: React.ChangeEventHandler<HTMLInputElement>,
  className?: string
}

export interface RadioGroupContextValue {
  name: string | undefined;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  value: any;
}

export const RadioGroupContext = React.createContext<RadioGroupContextValue | undefined>(undefined);

const RadioGroup = (props: Props) => {
  const {
    name,
    children,
    value,
    onChange,
    className
  } = props;

  const containerClasses = classnames({
    [`${classPrefix}-radio-group`]: true
  }, className)

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if(onChange) {
      onChange(e)
    }
  }

  return (
    <RadioGroupContext.Provider value={{value, onChange: handleOnChange, name}}>
      <div className={containerClasses}>
        {children}
      </div>
    </RadioGroupContext.Provider>
    )
}

export default RadioGroup;
