import React, {HTMLInputTypeAttribute, ReactNode, useEffect, useRef, useState} from "react";
import classnames from '../../utilities/classnames';
import {classPrefix} from "../../utilities/global";
import iconClear from '../../../assets/icon/icon-input-clear.svg';

import './index.scss';

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size' | 'type' | 'value'> {
  value: any;
  name: string,
  onChange?: React.ChangeEventHandler<HTMLInputElement>,
  size?: 'lg' | 'md' | 'sm' | 'xs',
  className?: string,
  icon?: ReactNode,
  type?: 'text' | 'number',
  error?: boolean,
  helperText?: string,
  errorMsg?: string,
  onClear?: () => void,
  onFocus?: () => void,
  onBlur?: () => void,
  onClick?: () => void;
  onIconClick?: () => void,
  disabled?: boolean,
  label?: string,
  placeholder?: string,
  readonly?: boolean,
}

const InputText = (props: Props) => {
  const {
    value,
    onChange,
    name,
    className,
    icon,
    type,
    error,
    helperText,
    onClear,
    onFocus,
    onBlur,
    disabled = false,
    errorMsg,
    label,
    placeholder,
    size = 'md',
    readonly,
    onIconClick,
    onClick,
    ...rest
  } = props;

  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const isInputDirty = value || value === 0;
  const isShowLabel = label && (isFocused || isInputDirty);
  const isShowClearIcon = !readonly && isInputDirty && !disabled && isFocused;
  const isShowDivider = isShowClearIcon && icon;


  const containerClasses = classnames(
    {
      [`${classPrefix}-input-text-container`]: true,
      [`${classPrefix}-input-text-container--focused`]: isFocused,
      [`${classPrefix}-input-text-container--show-label`]: isShowLabel,
      [`${classPrefix}-input-text-container--error`]: error,
      [`${classPrefix}-input-text-container--disabled`]: disabled,
      [`${classPrefix}-input-text-container--${size}`]: true,
      [`${classPrefix}-input-text-container--with-label`]: label,
    },
  )


  const labelClasses = classnames(
    {
      [`${classPrefix}-input-text-label`]: true,
      [`${classPrefix}-input-text-label--shown`]: isShowLabel,
    }
  )

  const inputClasses = classnames(
    {
      [`${classPrefix}-input-text`]: true,
    }
  )

  const controlClass = classnames(
    {
      [`${classPrefix}-input-text-control`]: true
    }
  )

  const clear = () => {
    onClear && onClear()
  }

  const containerFocus = () => {
    if(readonly) return;
    setIsFocused(true);
    inputRef.current?.focus()
    onFocus && onFocus()
  }

  const containerBlur = (e: any) => {
    if(containerRef.current !== e.relatedTarget) {
      setIsFocused(false);
      inputRef.current?.blur()
      onBlur && onBlur()
    }
  }

  return <div onClick={onClick} className={className}>
    <div
      tabIndex={0}
      onBlur={containerBlur}
      onFocus={containerFocus}
      ref={containerRef}
      className={containerClasses}
    >
      <div className={`${classPrefix}-input-text-content`}>
        {label && <label className={labelClasses} htmlFor={name}>{label}</label>}
        <input
          ref={inputRef}
          id={name}
          name={name}
          placeholder={placeholder || label}
          className={inputClasses}
          value={value}
          onChange={onChange}
          type={type}
          disabled={disabled}
          readOnly={readonly}
          {...rest}
        />
      </div>
      <div className={controlClass}>
        {isShowClearIcon &&<img onClick={clear} src={iconClear} alt={''}/>}
        {isShowDivider && <div className={`${classPrefix}-input-text-control-divider`}/>}
        {icon && <div onClick={onIconClick} className={`${classPrefix}-input-text-control-icon`}>
          {icon}
        </div>}
      </div>
    </div>
    {error && errorMsg && <div className={`${classPrefix}-input-text-helper-text`}>{errorMsg}</div>}
    {!error && helperText && <div className={`${classPrefix}-input-text-helper-text`}>{helperText}</div>}
  </div>

}

export default InputText;
