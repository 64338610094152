import React, {useContext} from "react";
import classnames from '../../utilities/classnames';
import {classPrefix} from "../../utilities/global";
import {ReactComponent as IconCheckboxChecked} from '../../../assets/icon/icon-radio-checked.svg';
import {ReactComponent as IconCheckboxUnChecked} from '../../../assets/icon/icon-radio-unchecked.svg';
import './index.scss';
import {RadioGroupContext} from "../radio-group";

interface Props {
  className?: string;
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>,
  onClick?: React.EventHandler<any>,
  value?: any;
  id?: string
}

const Radio = (props: Props) => {
  const {
    checked: checkedProp,
    name: nameProp,
    disabled,
    className,
    onChange: onChangeProp,
    onClick,
    value,
    id
  } = props;

  const radioGroup = useContext(RadioGroupContext);
  let checked = checkedProp;
  let name = nameProp;
  let onChange = onChangeProp;

  if(radioGroup) {
    if (typeof checkedProp === 'undefined') {
      checked = (radioGroup.value === value)
    }
    if (typeof nameProp === 'undefined') {
      name = radioGroup.name;
    }
    onChange = (e) => {
      onChangeProp && onChangeProp(e);
      radioGroup.onChange && radioGroup.onChange(e);
    }
  }

  const checkboxClasses = classnames(
    {
      [`${classPrefix}-radio`]: true,
      [`${classPrefix}-radio--checked`]: checked,
      [`${classPrefix}-radio--checked`]: !checked,
    },
    className
  );

  // console.log('')

  const iconClasses = classnames(
    {
      [`${classPrefix}-radio-icon`]: true,
      [`${classPrefix}-radio-icon--disabled`]: disabled,
      [`${classPrefix}-radio-icon--checked`]: checked,
      [`${classPrefix}-radio-icon--unchecked`]: !checked,
    }
  );

  return <span className={checkboxClasses}>
      {checked ? <IconCheckboxChecked className={iconClasses}/> : <IconCheckboxUnChecked className={iconClasses}/>}
      <input
        id={id}
        name={name}
        disabled={disabled}
        type={'radio'}
        className={`${classPrefix}-radio-input`}
        onChange={onChange}
        onClick={onClick}
        value={value}
        checked={checked}
      />
    </span>
}

export default Radio;
