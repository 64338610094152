import React, {ChangeEvent, useContext} from "react";
import classnames from '../../utilities/classnames';
import {classPrefix} from "../../utilities/global";
import {ReactComponent as IconCheckboxChecked} from '../../../assets/icon/icon-checkbox-checked.svg';
import {ReactComponent as IconCheckboxUnChecked} from '../../../assets/icon/icon-checkbox-unchecked.svg';
import './index.scss';
import {CheckboxGroupContext} from "../checkbox-group";

interface Props {
  className?: string;
  name?: string;
  checked?: number | boolean;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>,
  value?: any;
  id?: string
}

const Checkbox = (props: Props) => {
  const {
    checked: checkedProp,
    name: nameProp,
    disabled,
    className,
    onChange: onChangeProp,
    id,
    value
  } = props;
  const checkboxGroup = useContext(CheckboxGroupContext);

  let checked = checkedProp;
  let name = nameProp;
  let onChange = onChangeProp;

  if(checkboxGroup) {
    if (typeof checkedProp === 'undefined') {
      checked = checkboxGroup.value.includes(value);
    }
    if (typeof nameProp === 'undefined') {
      name = checkboxGroup.name;
    }
    onChange = (e) => {
      onChangeProp && onChangeProp(e);
      checkboxGroup.onChange && checkboxGroup.onChange(e);
    }
  }

  const checkboxClasses = classnames(
    {
      [`${classPrefix}-checkbox`]: true,
      [`${classPrefix}-checkbox--checked`]: checked,
      [`${classPrefix}-checkbox--checked`]: !checked,
    },
    className
  );

  const iconClasses = classnames(
    {
      [`${classPrefix}-checkbox-icon`]: true,
      [`${classPrefix}-checkbox-icon--disabled`]: disabled,
      [`${classPrefix}-checkbox-icon--checked`]: checked,
      [`${classPrefix}-checkbox-icon--unchecked`]: !checked,
    }
  );

  return <span className={checkboxClasses}>
      {checked ? <IconCheckboxChecked className={iconClasses}/> : <IconCheckboxUnChecked className={iconClasses}/>}
        <input
          id={id}
          name={name}
          disabled={disabled}
          type={'checkbox'}
          className={`${classPrefix}-checkbox-input`}
          onChange={onChange}
          value={value}
        />
    </span>
}

export default Checkbox;
