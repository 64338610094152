import React, {ReactNode, useEffect, useRef, useState} from 'react';
import {classPrefix} from "../../utilities/global";
import './index.scss';
import classnames from "../../utilities/classnames";
import {ReactComponent as IconClose} from '../../../assets/icon/icon-close.svg';
import iconInformSuccess from '../../../assets/icon/icon-inform-success.svg';
import iconInformError from '../../../assets/icon/icon-inform-error.svg';
import iconInformWarn from '../../../assets/icon/icon-inform-warn.svg';
import iconInformInfo from '../../../assets/icon/icon-inform-info.svg';

interface InformProps {
  icon?: ReactNode,
  content: string,
  type?: 'info' | 'success' | 'warning' | 'error',
  isShow?: boolean
  onClose?: () => void,
  fullWidth?: boolean,
  iconClose?: boolean,
  className?: string,
  defaultIcon?: boolean
}

const Inform = (props: InformProps) => {
  const {
    icon,
    content,
    type = 'info',
    isShow = true,
    onClose,
    fullWidth,
    iconClose,
    className,
    defaultIcon = true
  } = props;

  const iconMapping = {
    "success": iconInformSuccess,
    "error": iconInformError,
    "warning": iconInformWarn,
    "info": iconInformInfo,
  }

  const containerRef = useRef<HTMLDivElement>(null);
  const [isThick, setIsThick] = useState(false);
  useEffect(() => {
    if(!containerRef.current) return;

    if(containerRef.current?.clientHeight > 70) {
      setIsThick(true);
    }
  }, [containerRef])

  const containerClasses = classnames(
    {
      [`${classPrefix}-inform`]: true,
      [`${classPrefix}-inform--${type}`]: true,
      [`${classPrefix}-inform--fullwidth`]: fullWidth,
      [`${classPrefix}-inform--thick`]: isThick
    },
    className
  )

  if(!isShow) return <></>
  return <div ref={containerRef} className={containerClasses}>
      <div className={`${classPrefix}-inform-content`}>
        {icon && <span className={`${classPrefix}-inform-icon`}>{icon}</span>}
        {
          defaultIcon && !icon && <img src={iconMapping[type]} alt={''}/>
        }
        <span className={`${classPrefix}-inform-text`}>{content}</span>
      </div>

      {iconClose && <IconClose
          onClick={() => {
            onClose && onClose()
          }}
          className={`${classPrefix}-inform-icon-close`}
      />}
  </div>
}

export default Inform;
