import React, {ReactNode} from 'react';
import classnames from '../../utilities/classnames';
import {classPrefix} from "../../utilities/global";
import './index.scss'
interface Props {
  control: React.ReactElement<any, any>,
  label: string,
  className?: string,
  labelClassName?: string
}

const ControlLabel = (props: Props) => {
  const {
    control,
    label,
    className,
    labelClassName
  } = props;

  const containerClasses = classnames({
    [`${classPrefix}-control-label-container`]: true
  }, className)

  const {
    disabled,
    id
  } = control?.props || {};

  const labelClasses = classnames({
    [`${classPrefix}-control-label`]: true,
    [`${classPrefix}-control-label--disabled`]: disabled,
  }, labelClassName)

  return <label htmlFor={id} className={containerClasses}>
    {control}
    <span className={labelClasses}>{label}</span>
  </label>
}

export default ControlLabel;
