// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clp-pink-25": "#faf3f6",
	"clp-pink-100": "#dfa8bb",
	"clp-pink-300": "#d56189",
	"clp-pink-500": "#b3446d",
	"clp-pink-600": "#8f3657",
	"clp-pink-700": "#c81b56",
	"clp-pink-900": "#a4053b",
	"clp-violet-25": "#ffe4f6",
	"clp-violet-100": "#f094d2",
	"clp-violet-300": "#d259ab",
	"clp-violet-400": "#b1408c",
	"clp-violet-500": "#a2387f",
	"clp-violet-700": "#7d2660",
	"clp-gray-25": "#e0e0e0",
	"clp-gray-50": "#f2f2f2",
	"clp-gray-100": "#bdbdbd",
	"clp-gray-500": "#828282",
	"clp-grey-600": "#828282",
	"clp-gray-700": "#4f4f4f",
	"clp-gray-900": "#333",
	"clp-orange-25": "#f2dfdf",
	"clp-orange-50": "#ffefeb",
	"clp-orange-100": "#d9a2a2",
	"clp-orange-300": "#e4532c",
	"clp-orange-500": "#ee5b33",
	"clp-orange-700": "#da4017",
	"clp-orange-900": "#b72a05",
	"clp-red-25": "#f0cbcd",
	"clp-red-50": "#fae9ea",
	"clp-red-100": "#f6d2d4",
	"clp-red-200": "#eca5a9",
	"clp-red-300": "#e3787f",
	"clp-red-400": "#d94b54",
	"clp-red-500": "#d01e29",
	"clp-red-600": "#a61821",
	"clp-red-700": "#d01e29",
	"clp-red-800": "#530c10",
	"clp-red-900": "#2a0608",
	"clp-blue-25": "#f3f8fc",
	"clp-blue-50": "#e8f0fa",
	"clp-blue-100": "#d1e1f4",
	"clp-blue-200": "#a3c3ea",
	"clp-blue-300": "#74a6df",
	"clp-blue-400": "#4688d5",
	"clp-blue-500": "#186aca",
	"clp-blue-600": "#1355a2",
	"clp-blue-700": "#0e4079",
	"clp-blue-800": "#0a2a51",
	"clp-blue-900": "#051528",
	"clp-green-25": "#f4f8f2",
	"clp-green-50": "#ecf7e8",
	"clp-green-100": "#d3e5cc",
	"clp-green-200": "#a6ca99",
	"clp-green-300": "#7ab066",
	"clp-green-400": "#4d9533",
	"clp-green-500": "#217b00",
	"clp-green-600": "#1a6200",
	"clp-green-700": "#144a00",
	"clp-green-800": "#0d3100",
	"clp-green-900": "#071900",
	"clp-indigo-25": "#f7f7fe",
	"clp-indigo-50": "#efeefc",
	"clp-indigo-100": "#e0ddfa",
	"clp-indigo-200": "#c1bbf4",
	"clp-indigo-300": "#a19aef",
	"clp-indigo-400": "#8278e9",
	"clp-indigo-500": "#6356e4",
	"clp-indigo-600": "#4f45b6",
	"clp-indigo-700": "#3b3489",
	"clp-indigo-800": "#28225b",
	"clp-indigo-900": "#14112e",
	"clp-teal-25": "#f7fbfe",
	"clp-teal-50": "#eef7fe",
	"clp-teal-100": "#ddf0fd",
	"clp-teal-200": "#bbe1fa",
	"clp-teal-300": "#99d1f8",
	"clp-teal-400": "#77c2f5",
	"clp-teal-500": "#55b3f3",
	"clp-teal-600": "#448fc2",
	"clp-teal-700": "#336b92",
	"clp-teal-800": "#224861",
	"clp-teal-900": "#112431",
	"clp-white-25": "#fff",
	"clp-white-50": "#fff",
	"clp-white-100": "#fff",
	"clp-white-200": "#fff",
	"clp-white-300": "#fff",
	"clp-white-400": "#fff",
	"clp-white-500": "#fff",
	"clp-white-600": "#ccc",
	"clp-white-700": "#999",
	"clp-white-800": "#666",
	"clp-white-900": "#333",
	"clp-dark-25": "#f4f5f7",
	"clp-dark-50": "#e9eaef",
	"clp-dark-100": "#d2d6de",
	"clp-dark-200": "#a6adbe",
	"clp-dark-300": "#79839d",
	"clp-dark-400": "#4d5a7d",
	"clp-dark-500": "#20315c",
	"clp-dark-600": "#1a274a",
	"clp-dark-700": "#131d37",
	"clp-dark-800": "#0d1425",
	"clp-dark-900": "#060a12",
	"fs-p-sm": "12px",
	"fs-p-md": "14px",
	"fs-p-lg": "16px",
	"fs-p-xs": "10px",
	"fs-h-sm": "18px",
	"fs-h-md": "24px",
	"fs-h-lg": "32px",
	"fs-l-xs": "10px",
	"fs-l-sm": "12px",
	"fs-l-md": "14px",
	"fs-l-lg": "16px",
	"lh-p-sm": "14.4px",
	"lh-p-md": "16.8px",
	"lh-p-lg": "19.2px",
	"lh-p-xs": "12px",
	"lh-h-sm": "21.6px",
	"lh-h-md": "28.8px",
	"lh-h-lg": "38.4px",
	"lh-l-xs": "12px",
	"lh-l-sm": "14.4px",
	"lh-l-md": "16.8px",
	"lh-l-lg": "19.2px"
};
export default ___CSS_LOADER_EXPORT___;
