import React, {useState} from 'react'
import {ReactComponent as IconSnackBarInfo} from '../../../assets/icon/icon-snack-bar-info.svg'
import {ReactComponent as IconSnackBarSuccess } from '../../../assets/icon/icon-snack-bar-success.svg'
import {ReactComponent as IconSnackBarError } from '../../../assets/icon/icon-snack-bar-error.svg'
import classnames from '../../utilities/classnames';
import {classPrefix} from "../../utilities/global";

import './index.scss';

interface Props {
    isShow: boolean
    message: string
    type: 'info' | 'success' | 'warning' | 'error',
}

const data = {
    info: IconSnackBarInfo,
    success: IconSnackBarSuccess,
    error: IconSnackBarError,
    warning: IconSnackBarInfo
}

const SnackBar = (props: Props) => {
    const {
        isShow = false,
        message,
        type = 'info',
    } = props;
    const containerClasses = classnames(
      {
          [`${classPrefix}-snack-bar-container`]: true,
          [`${classPrefix}-snack-bar--show`]: isShow,
          [`${classPrefix}-snack-bar--hidden`]: !isShow,
      }
    );
    const contentClasses = classnames(
      {
          [`${classPrefix}-snack-bar`]: true,
          [`${classPrefix}-snack-bar--${type}`]: true
      }
    )

    const IconComp = data[type];

    return <div className={containerClasses}>
        <div className={contentClasses}>
            <IconComp className={`${classPrefix}-snack-bar-icon`}/>
            <img  src={data[type]} alt={""}/>
            <span>{message}</span>
        </div>
    </div>
}

export default SnackBar;
