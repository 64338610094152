import React, {ReactElement, ReactEventHandler, ReactNode} from 'react'
import classNames from '../../../utilities/classnames';
import {classPrefix} from "../../../utilities/global";
import './index.scss';

interface ToastCenterProps {
  icon?: ReactElement,
  isShow?: boolean,
  content?: string
}

export const ToastCenter = (props: ToastCenterProps) => {
  const {
    content,
    isShow,
    icon
  } = props;

  const containerClasses = classNames(
    {
      [`${classPrefix}-toast-center`]: true,
      [`${classPrefix}-toast-center--show`]: isShow,
      [`${classPrefix}-toast-center--hidden`]: !isShow,
    }
  )

  return <div className={containerClasses}>
    {icon && <span className={`${classPrefix}-toast-center-icon`}>{icon}</span>}
    {content}
  </div>
}
export default ToastCenter;
