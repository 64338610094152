export interface CoreWebStorage {
	getItem(key: string, ...args: Array<any>): any;
	setItem(key: string, value: any, ...args: Array<any>): any;
	removeItem(key: string, ...args: Array<any>): any;
	[key: string]: any
}

class BrowserStorageUtil {
	constructor(storageType: Storage) {
		this._storage = storageType;
	}
	_storage: Storage
	public serializedSave(key: string, data: any) {
		try {
			this._storage.setItem(key, JSON.stringify(data))
		} catch (err) {
			console.error(err);
		}
	}

	public deserializedGet(key: string) {
		const data = this._storage.getItem(key);
		try {
			if(!data) return;
			return JSON.parse(data as any)
		} catch (err) {
			console.error(err);
			return undefined
		}
	}
}

export default BrowserStorageUtil;