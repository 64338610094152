// Types
export interface ValidationGroup {
  [key: string]: Validator
}

export type Validator =  (value: any, ...args: any[]) => boolean | string;

// Constants
export const MAX_NAME_LENGTH = 45;
export const MIN_PHONE_LENGTH = 9;
export const MAX_PHONE_LENGTH = 11;
export const MIN_PASSWORD_LENGTH = 6;
export const MAX_PASSWORD_LENGTH = 20;
export const MAX_ID_LENGTH = 20;


// Validators
export const blankSpace:Validator = (val: string) => (val && /(^\s+)|(\s+$)/.test(val)) || 'validation_input_blank_space'

export const maxLength:Validator = (val: string, length: Number) => (val && val.length <= length) || 'validation_max_length';

export const minLength:Validator = (val: string, length: Number) => (val && val.length >= length) || 'validation_min_length';

export const rangeLength:Validator = (val: string, min: Number, max: Number) => (val && val.length >= min && val.length <= max ) || 'validation_range_length'

export const required:Validator = (val: any) => (val !== undefined && val !== null && val !== '') || 'validation_required'

export const emailPattern:Validator = (val: string) => (val && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val)) || 'validation_email_pattern'

// ValidationGroup
export const nameValidationGroup:ValidationGroup = {
  blankSpace,
  maxLength: (val) => maxLength(val, MAX_NAME_LENGTH)
}

export const phoneValidationGroup:ValidationGroup = {
  rangeLength: (val) => rangeLength(val, MIN_PHONE_LENGTH, MAX_PHONE_LENGTH)
}

export const passwordValidationGroup:ValidationGroup = {
  rangeLength: (val: string) => rangeLength(val, MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH),
  required
}

export const idValidationGroup:ValidationGroup = {
  maxLength: (val: string) => maxLength(val, MAX_ID_LENGTH)
}

export const emailValidationGroup:ValidationGroup = {
  emailPattern
}

