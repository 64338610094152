import React, {ReactNode} from 'react';
import classnames from '../../utilities/classnames';
import {classPrefix} from "../../utilities/global";
import {ReactComponent as IconClose} from '../../../assets/icon/icon-close.svg';
import {ReactComponent as IconCloseCircle} from '../../../assets/icon/icon-close-circle.svg';
import './index.scss';
import Button from "../button";
export interface PopupContainerProps {
  children: ReactNode,
  isOpen: boolean,
  onClose?: React.MouseEventHandler<HTMLElement>,
  width?: number,
  className?: string
}

export interface PopupProps {
  isOpen: boolean,
  image: ReactNode,
  title: string,
  onClose?: React.MouseEventHandler<HTMLElement>,
  imageSize?: 'lg' | 'sm',
  width?: number,
  subTitle?: string,
  mainButtonText?: string,
  subButtonText?: string,
  onMainButtonClick?: React.MouseEventHandler<HTMLElement>,
  onSubButtonClick?: React.MouseEventHandler<HTMLElement>,
}

export const PopupContainer = (props: PopupContainerProps) => {
  const {
    children,
    isOpen,
    onClose,
    width,
    className
  } = props;

  const overlayClasses = classnames({
    [`${classPrefix}-popup-overlay`]: true,
    [`${classPrefix}-popup-overlay--open`]: isOpen,
    [`${classPrefix}-popup-overlay--closed`]: !isOpen,
  })

  const containerClasses = classnames({
    [`${classPrefix}-popup-container`]: true,
    [`${classPrefix}-popup-container--open`]: isOpen,
    [`${classPrefix}-popup-container--closed`]: !isOpen,
  }, className)

  return <>
    <div onClick={onClose} className={overlayClasses}>
      <div style={{width: width}} className={containerClasses}>
        {children}
      </div>
    </div>
  </>
}

const Popup = (props: PopupProps) => {
  const {
    isOpen,
    onClose,
    imageSize = 'lg',
    image,
    width,
    title,
    subTitle,
    mainButtonText,
    subButtonText,
    onMainButtonClick,
    onSubButtonClick
  } = props;

  const popupClasses = classnames({
    [`${classPrefix}-popup`]: true,
    [`${classPrefix}-popup--${imageSize}`]: true
  })

  const imageClasses = classnames({
    [`${classPrefix}-popup-image`]: true,
    [`${classPrefix}-popup-image--${imageSize}`]: true
  })

  const onContentClick = (e: any) => {
    e.stopPropagation();
  }

  const IconImg = imageSize === 'sm' ? IconClose : IconCloseCircle

  return <PopupContainer width={width} isOpen={isOpen} onClose={onClose}>
    <div onClick={onContentClick} className={popupClasses}>
      <IconImg onClick={onClose} className={`${classPrefix}-popup-icon-close`}/>
      <div className={imageClasses}>
        <div className={`${classPrefix}-popup-image-content`}>
          {image}
        </div>
      </div>
      <div className={`${classPrefix}-popup-body`}>
        <div className={`${classPrefix}-popup-title`}>
          {title}
        </div>
        <div className={`${classPrefix}-popup-sub-title`}>
          {subTitle}
        </div>
        {mainButtonText && <Button onClick={onMainButtonClick} className={`${classPrefix}-popup-main-btn`} bold size={'md'}>{mainButtonText}</Button>}
        {subButtonText && <Button onClick={onSubButtonClick} className={`${classPrefix}-popup-sub-btn`} bold variant={'text'} size={'md'}>{subButtonText}</Button>}
      </div>
    </div>
  </PopupContainer>
}

export default Popup;
