import React, {ReactNode} from 'react';
import './index.scss';
import {classPrefix} from "../../utilities/global";
import classnames from '../../utilities/classnames';

interface Props {
  variant?: 'primary' | 'text' | 'secondary'
  size?: 'lg' | 'md' | 'sm' | 'xs'
  disabled?: boolean
  children?: ReactNode,
  className?: string
  icon?: JSX.Element
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
  bold?: boolean,
  type?: 'submit' | 'button' | 'reset'
}

const Button = (props: Props) => {
  const {
    variant = 'primary',
    size = 'sm',
    disabled = false,
    children,
    className,
    icon,
    onClick = () => {},
    bold,
    type
  } = props;
  const containerClasses = classnames({
    [`${classPrefix}-btn`]: true,
    [`${classPrefix}-btn-${variant}`]: true,
    [`${classPrefix}-btn-${size}`]: true,
    [`${classPrefix}-btn-${variant}-disabled`]: disabled,
    [`${classPrefix}-btn-bold`]: bold,
  },
    className
  )

  const iconClasses = classnames({
    [`${classPrefix}-btn-${size}-icon`]: true,
    [`${classPrefix}-btn-icon-only`]: !props.children
  })

  return <button type={type} onClick={onClick} className={containerClasses}>
    {icon && <span className={iconClasses}>{icon}</span>}
    {children}
  </button>
}

export default Button;
