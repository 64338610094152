import React, {useState} from "react";
import classNames from '../../utilities/classnames';
import {classPrefix} from "../../utilities/global";

import './index.scss'

interface tab {
    label: string
    value: string
}

interface Props {
    tabs: tab[]
    onTabChange: (val: any) => void
    currentTabValue: any
}

const Tab = ({tabs, onTabChange, currentTabValue} : Props) => {
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const handleTabChange = (e: any, value: string, idx: number) => {
        setActiveTabIndex(idx);
        return onTabChange(value);
    }

    return <div className={`${classPrefix}-tab`}>
        {tabs.map(({label, value}, idx) => {
            const tabItemClasses = classNames(
              {
                  [`${classPrefix}-tab-item`]: true,
                  [`${classPrefix}-tab-item-active`]: currentTabValue === value,
                  [`${classPrefix}-tab-item-inactive`]: currentTabValue !== value,
              }
            )
            return  <div
                onClick={(e) => handleTabChange(e, value, idx)}
                key={value}
                className={tabItemClasses}
            >
                {label}
            </div>
        })}
        <div className={`${classPrefix}-tab-indicator`} style={{width: `${100/tabs.length}%`, left: `${activeTabIndex * 100/tabs.length}%`}}/>
    </div>
};

export default Tab;
