// @ts-ignore
import scssExports from './_export.scss';

const parseColorPalette = (scssColorPalette: any) => {
  const colors = Object.keys(scssColorPalette);

  return colors.reduce((result, next) => {
    const [prefix, colorName, colorLevel] = next.split('-');
    if(prefix !== 'clp') return result;
    if(!result[colorName]) result[colorName] = {};

    result[colorName][`${colorName}-${colorLevel}`] = scssColorPalette[next];

    return result;
  }, {} as any)
}

const parseTypo = (scssExports: any) => {
  const items = Object.keys(scssExports);
  const nameMapping = ({
    'fs': 'fontSize',
    'lh': 'lineHeight',
  } as any)
  return items.reduce((result, next) => {
    const [prefix, typoType, typoSize] = next.split('-');
    if(prefix === 'clp') return result;

    const name = nameMapping[prefix];

    if(!result[name]) result[name] = {};
    if(!result[name][typoType]) result[name][typoType] = {}

    result[name][typoType][typoSize] = scssExports[next];

    return result;
  }, {} as any)
}

interface typography {
  fontSize: any;
  lineHeight: any
}

export interface ScssExports {
  colorPalette: any,
  typography: typography
}

const _export:ScssExports  = {
  colorPalette: parseColorPalette(scssExports),
  typography: parseTypo(scssExports)
}

export default _export;
