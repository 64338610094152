import React, {ChangeEvent, ReactNode} from 'react';
import {classPrefix} from "../../utilities/global";
import classnames from '../../utilities/classnames';

interface Props {
  name?: string,
  children?: ReactNode,
  value: any,
  onChange: (val: any[]) => void;
  className?: string
}

export interface CheckboxGroupContextValue {
  name: string | undefined;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  value: any[];
}

export const CheckboxGroupContext = React.createContext<CheckboxGroupContextValue | undefined>(undefined);

const CheckboxGroup = (props: Props) => {
  const {
    name,
    children,
    value,
    onChange,
    className
  } = props;

  const containerClasses = classnames({
    [`${classPrefix}-radio-group`]: true
  }, className)

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isOptionChecked = e.target.checked;
    const optionValue = e.target.value;

    let newValue;
    if(!isOptionChecked) {
      newValue = value.filter((value: any) => value !== optionValue);
    } else {
      newValue = [...value, optionValue]
    }
    onChange(newValue)
  }

  return (
    <CheckboxGroupContext.Provider value={{value, onChange: handleOnChange, name}}>
      <div className={containerClasses}>
        {children}
      </div>
    </CheckboxGroupContext.Provider>
  )
}

export default CheckboxGroup;
