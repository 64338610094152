import React, {ReactElement, ReactEventHandler, ReactNode} from 'react'
import classNames from '../../../utilities/classnames';
import {classPrefix} from "../../../utilities/global";
import './index.scss';
import Button from "../../button";

interface ToastBottomProps {
  icon?: ReactElement,
  controlContent?: string,
  isShow?: boolean,
  onButtonClick?: React.MouseEventHandler<HTMLButtonElement>,
  content?: string
}

const ToastBottom = (props: ToastBottomProps) => {
  const {
    icon,
    controlContent,
    isShow = false,
    onButtonClick,
    content
  } = props;
  const containerClasses = classNames(
    {
      [`${classPrefix}-toast-bottom`]: true,
      [`${classPrefix}-toast-bottom--show`]: isShow,
      [`${classPrefix}-toast-bottom--hidden`]: !isShow,
    }
  )

  return <div className={containerClasses}>
    <div>
      {icon && <span className={`${classPrefix}-toast-bottom-icon`}>{icon}</span>}
      <span>{content}</span>
    </div>
    {controlContent && <div className={`${classPrefix}-toast-bottom-control`}>
      <span className={`${classPrefix}-toast-bottom-divider`}/>
      <Button
        className={`${classPrefix}-toast-bottom-button`}
        variant={'text'}
        size={'sm'}
        onClick={onButtonClick}
      >
        {controlContent}
      </Button>
    </div>}
  </div>
}

export default ToastBottom;
