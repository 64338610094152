import {CoreWebStorage} from "./index";
// @ts-ignore
import cookieJS from 'js-cookie'

const cookieStorage: CoreWebStorage = {
    getItem(key: string, ...args): any {
        return cookieJS.get(key, ...args)
    },
    setItem(key: string, value: any, ...args): any {
        return cookieJS.set(key, value, ...args)
    },
    removeItem(key: string, ...args): any {
        return cookieJS.remove(key, ...args)
    },
}

export default cookieStorage;