import {useState} from 'react';

const useToggle = (timeout = 5000): any => {
  const [isShow, setIsShow] = useState(false);
  const [timeOutId, setTimeoutId] = useState<any>();

  const trigger = () => {
    if(isShow) return;
    setIsShow(true);

    setTimeoutId(setTimeout(() => {
      setIsShow(false)
    }, timeout))
  }

  const close = () => {
    if(timeOutId) {
      clearTimeout(timeOutId)
    }
    setIsShow(false);
  }


  return [isShow, trigger, close]
}

export default useToggle;
